import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { BottomDivider, SectionContainerGridFourCols, SectionTitleContainer, SectionWrapperSmall } from "../components/styles/Sections"
import ArticleComponent from "../components/ArticleComponent"
import BreadCrumbs from "../components/breadcrumbs"
import { Button } from "../components/styles/Buttons"
import Bio from "../components/bio"

const AuthorArchive = ({ data: { author } }) => {

  /* CUSTOM ARRAY TO RENDER BREADCRUMBS AS IT IS AN ARCHIVE PAGE */
  const crumbs = [ 
    {
    "text": "Hem",
    "url": "https://svenskaonlinecasinon.se"
    },
    {
      "text": "Skribenter",
      "url": "/skribenter",
    },
    {
      "text": author.name,
    }
  ]

  return (
    <Layout isHomePage>
      <Seo title={"Nyheter och recensioner skrivna av " + author.name} description={"Här hittar du alla inlägg och nyheter skrivna av" + author.name} />
      <BreadCrumbs items={crumbs} hideDate={true} />
      <SectionWrapperSmall>
        <h1>Författararkiv {author.name}</h1>
        <Bio author={author} hideName={true} />
        {author.posts && author.posts.nodes.length > 0 &&
          <>
            <SectionTitleContainer hasButton>
              <div>
                <h2>Recensioner</h2>
                <span>Antal recensioner av {author.name}: {author.posts.nodes.length}</span>
              </div>
            </SectionTitleContainer>
            <SectionContainerGridFourCols>
              {author.posts.nodes.map((post, i) => (
              <ArticleComponent item={post} key={i} />
              ))}
            </SectionContainerGridFourCols>
            <BottomDivider />
          </>
        }
        {author.nyheter && author.nyheter.nodes.length > 0 &&
          <>
            <SectionTitleContainer hasButton>
            <div>
              <h2>Nyheter</h2>
              <span>Antal nyheter av {author.name}: {author.nyheter.nodes.length}</span>
            </div>
              <Link to="/nyheter">
                <Button className="blue">Visa alla nyheter</Button>
              </Link>
            </SectionTitleContainer>
            <SectionContainerGridFourCols>
              {author.nyheter.nodes.map((nyhet, i) => (
                <ArticleComponent item={nyhet} key={i} />
              ))}
            </SectionContainerGridFourCols>
            <BottomDivider />
          </>
        }
        {author.guider && author.guider.nodes.length > 0 &&
          <>
            <SectionTitleContainer hasButton>
              <div>
                <h2>Guider</h2>
                <span>Antal guider av {author.name}: {author.guider.nodes.length}</span>
              </div>
              <Link to="/guider">
                <Button className="blue">Visa alla guider</Button>
              </Link>
            </SectionTitleContainer>
            <SectionContainerGridFourCols>
              {author.guider.nodes.map((guide, i) => (
                <ArticleComponent item={guide} key={i} />
              ))}
            </SectionContainerGridFourCols>
          </>
        }
      </SectionWrapperSmall>
    </Layout>
  )
}

export default AuthorArchive

export const authorQuery = graphql`
  query AuthorByID(
    $id: String!
  ) {
    author: wpUser(id: { eq: $id}) {
      id
      name
      slug
      description
      uri
      authorSocials {
        linkedin
      }
      avatar {
        url
      }
      seo {
        title
        metaDesc
      }
      posts {
        nodes {
          title
          slug
          uri
          date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
      nyheter {
        nodes {
          title
          slug
          uri
          date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
      guider {
        nodes {
          title
          slug
          uri
          date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

